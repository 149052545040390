<template>
  <div>
    <v-sheet class="header-backup" />
    <v-app-bar
      :color="$vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor"
      :dark="getThemeMode.appBarColor != 'white' ? true : false"
      app
      class="px-sm text-left shadow-sm ma-4 rounded"
      flat
      height="75"
    >
      <v-app-bar-nav-icon
        v-ripple="{ class: 'primary--text' }"
        @click="toggleVerticalSidebarDrawer"
      />
      <!-- <vue-navigation-bar
        class="mt-0"
        :options="navbarOptions"
      /> -->
      <v-progress-linear
        :active="getThemeMode.isLoading"
        :indeterminate="getThemeMode.isLoading"
        absolute
        bottom
        color="primary"
      />

      <!-- <v-toolbar-title>Egret Vue</v-toolbar-title> -->

      <v-spacer />

      <v-badge
        bordered
        overlap
        :content="helpMsgNum"
        color="red"
        offset-x="22"
        offset-y="22"
      >
        <v-btn icon @click="notificationDrawer = !notificationDrawer">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>

      <!-- 검색 -->
      <!-- <v-btn icon @click="searchDrawerOpen">
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->

      <v-chip pill class="transparent py-5" @click="userDrawer = !userDrawer">
        <!-- 홍길동 -->
        Welcome, {{ accessInfo.loginUserName }}
        <!-- <span class="hGap"></span> -->
        <!-- <b> {{ accessInfo.userType === "super" ? "(Manager)" : ` at ${accessInfo.hospital_name}` }}</b>) -->
        <!-- {{ $cValue.staffTypeToLbl($store.getters.loggedInUser.staffType) }} -->
        <v-avatar class="ml-2">
          <img
            v-if="
              $store.getters.loggedInUser !== null &&
              $store.getters.loggedInUser &&
              $store.getters.loggedInUser.imgPath !== ''
            "
            :src="
              $store.getters.loggedInUser && $store.getters.loggedInUser.imgPath
            "
          />
          <v-img v-else src="@/assets/images/faces/doctor_m.png" />

          <!-- <v-img :src="$store.getters.getCurrentUser[0].avatar" /> -->
        </v-avatar>
      </v-chip>
    </v-app-bar>

    <!-- userDrawer -->
    <v-navigation-drawer
      v-model="userDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350"
    >
      <user-drawer
        :PropsAccessInfo="accessInfo"
        @closeDrawer="userDrawer = false"
      >
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="userDrawer = !userDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button
            text="Logout"
            block
            bg-color="primary lighten-5 primary--text"
            icon-name="mdi-logout"
            @click.native="logoutUser"
          />
        </div>
      </template>
    </v-navigation-drawer>

    <!-- notificationDrawer  -->
    <v-navigation-drawer
      v-model="notificationDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350"
    >
      <notification-drawer :helpData="helpMsg" @saveComplete="saveComplete">
        <template v-slot:notificationDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="notificationDrawer = !notificationDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </notification-drawer>

      <!-- <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button
            text="View All Notifications"
            block
            bg-color="primary lighten-5 primary--text"
          />
        </div>
      </template> -->
    </v-navigation-drawer>

    <!-- searchDrawer : 검색창 -->
    <v-navigation-drawer
      v-model="searchDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="380"
    >
      <search-drawer
        :searchDrawer="searchDrawer"
        @closeDrawer="searchDrawer = false"
      >
        <template v-slot:searchDrawerCloseButton>
          <v-btn icon color @click.stop="searchDrawer = !searchDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </search-drawer>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Cookies from "js-cookie";
const modelService = require("@/utils/dataModel");

export default {
  name: "VerticallAppBar",
  components: {
    UserDrawer: () => import("../common-drawer/UserDrawer.vue"),
    NotificationDrawer: () => import("../common-drawer/NotificationDrawer.vue"),
    SearchDrawer: () => import("../common-drawer/SearchDrawer.vue"),
  },
  computed: {
    accessInfo() {
      const CookieName = process.env.VUE_APP_CookieName;
      return JSON.parse(Cookies.get(CookieName));
    },
    ...mapGetters(["getThemeMode"]),
  },
  data() {
    return {
      userDrawer: false,
      notificationDrawer: false,
      searchDrawer: false,
      navbarOptions: {
        elementId: "main-navbar",
        isUsingVueRouter: true,
        mobileBreakpoint: 992,
        brandImagePath: "./",
        brandImageAltText: "brand-image",
        collapseButtonOpenColor: "#661c23",
        collapseButtonCloseColor: "#661c23",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "Main Navigation",
        tooltipAnimationType: "shift-away",
      },
      helpMsgNum: 0,
      helpMsg: null,
      reqData: modelService.inquiryDataModel()
    };
  },
  mounted() {
    // console.log("", this.$store.getters.getCurrentUser[0].name);
    this.getHelpData();
  },
  methods: {
    ...mapActions([
      "changeVerticalSidebarDrawer",
      "changeVerticalSidebarMini",
      "signOut",
    ]),
    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer();

      // this.$emit("update:mini-variant");
      // console.log("check");
    },
    logoutUser() {
      console.log("logout");

      this.signOut();
      this.$router.push("/login");
      //환자 조회화면에서 로그아웃 시도하는 경우
      // if (this.$router.history.current.path === "/patientRead") {
      //   this.$eventBus.$emit("logout", {});
      // } else {

      //   this.$router.push("/login");
      // }
    },
    searchDrawerOpen() {
      this.searchDrawer = !this.searchDrawer;
    },
    getHelpData() {
      const $h = this.$helper;
      this.getDataQuery(this.reqData).then((res) => {
        if(res === undefined) return;
        console.log('res', res);
        // this.helpMsgNum = res.count;
        let resP = res.data;
        if (0 < resP.length) {
          resP = resP.map((item, index) => ({
            ...item,
            avatar: $h.makeUserAvatarImg(item.userPhotoImg, $h.isPhotoChecker(item.userPhotoImg), item.gender),
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
          }));
        }
        resP.forEach(element => {
          console.log('element', element.isComplete);
          if(element.isComplete === '' || element.isComplete === 'N' || element.isComplete === null) {
            this.helpMsgNum = this.helpMsgNum + 1;
          }
          // console.log('helpMsgNum', this.helpMsgNum);
        });
        this.helpMsg = resP;
      })
    },
    saveComplete(item) {
      console.log('item', item);
      item.endpoint = "inquiryComplete";
      this.saveDataQuery(item).then((res) => {
        if(res === undefined) return;
        console.log('res', res);
        this.getHelpData();
      });
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>

<style lang="scss">
.hGap {
  width: 10px;
}
.app-admin-wrap-layout-1.sidebar-mini .v-toolbar {
  // left: 56px !important;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
.header-backup {
  display: block;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  ) !important;
  left: 0;
  &.theme--dark {
    background: linear-gradient(
      180deg,
      rgba(5, 5, 5, 0.95) 44%,
      rgba(0, 0, 0, 0.46) 3%,
      hsla(0, 0%, 100%, 0)
    ) !important;
  }
}
.tippy-box[data-theme~="light"] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.vnb {
  background: transparent !important;
  &__menu-options {
    margin-top: 3px !important;
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: #000 !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }
  &:after {
    content: "\F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }
  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}
</style>
